import { TailSpin } from 'react-loader-spinner';

const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <TailSpin
      height="100"
      width="100"
      color="blue"
      ariaLabel="loading"
    />
  </div>
);

export default Loader;