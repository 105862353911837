// import { BrowserRouter, Route, Routes } from "react-router-dom";

// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";

// import Viajes from "./componentes/Viajes/Viajes";
// import Gastos from "./componentes/Gastos/Gastos";
// import Detalles from "./componentes/Detalles/Detalles";
// import Fiados from "./componentes/Fiados/Fiados";
// import Info from "./componentes/Info/Info";

// import InstallButton from "./componentes/InstallButton";
// import Ajustes from "./componentes/Ajustes/Ajustes";

// function App() {
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Viajes />} />
//           <Route path="/gastos" element={<Gastos />} />
//           <Route path="/fiados" element={<Fiados />} />
//           <Route path="/detalles" element={<Detalles />} />
//           <Route path="/ajustes" element={<Ajustes />} />
//           <Route path="/info" element={<Info />} />
//         </Routes>
//         <InstallButton/>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;



import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./componentes/Loader";

const Viajes = lazy(() => import("./componentes/Viajes/Viajes"));
const Gastos = lazy(() => import("./componentes/Gastos/Gastos"));
const Detalles = lazy(() => import("./componentes/Detalles/Detalles"));
const Fiados = lazy(() => import("./componentes/Fiados/Fiados"));
const Info = lazy(() => import("./componentes/Info/Info"));
const Ajustes = lazy(() => import("./componentes/Ajustes/Ajustes"));
const InstallButton = lazy(() => import("./componentes/InstallButton"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path="/" element={<Viajes />} />
            <Route path="/gastos" element={<Gastos />} />
            <Route path="/fiados" element={<Fiados />} />
            <Route path="/detalles" element={<Detalles />} />
            <Route path="/ajustes" element={<Ajustes />} />
            <Route path="/info" element={<Info />} />
          </Routes>
          <InstallButton />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
