import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { formatAmount } from "../utils/formatAmount";

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [isOwner, setIsOwner] = useState(() => localStorage.getItem("auto-propio") === "true");
  const agenciaPorcentaje = 20;
  const movilPorcentaje = 50;
  const choferPorcentaje = 30;

  const [data, setData] = useState(() => ({
    trips: JSON.parse(localStorage.getItem("trips")) || [],
    gastos: JSON.parse(localStorage.getItem("gastos")) || [],
    fiados: JSON.parse(localStorage.getItem("fiados")) || [],
  }));

  useEffect(() => {
    localStorage.setItem("trips", JSON.stringify(data.trips));
    localStorage.setItem("gastos", JSON.stringify(data.gastos));
    localStorage.setItem("fiados", JSON.stringify(data.fiados));
  }, [data]);

  const setTrips = (trips) => setData((prevData) => ({ ...prevData, trips }));
  const setGastos = (gastos) => setData((prevData) => ({ ...prevData, gastos }));
  const setFiados = (fiados) => setData((prevData) => ({ ...prevData, fiados }));

  const resetData = () => {
    localStorage.removeItem("trips");
    localStorage.removeItem("gastos");
    localStorage.removeItem("fiados");
    setData({ trips: [], gastos: [], fiados: [] });
  };

  const numeroViajes = data.trips.length;
  const totalCaja = useMemo(() => data.trips.reduce((total, trip) => total + (parseFloat(trip.amount) || 0), 0), [data.trips]);
  const totalGastos = useMemo(() => data.gastos.reduce((total, gasto) => total + (parseFloat(gasto.importe) || 0), 0), [data.gastos]);
  const totalFiado = useMemo(() => data.fiados.reduce((total, fiado) => total + (parseFloat(fiado.amount) || 0), 0), [data.fiados]);

  const choferGanancia = useMemo(() => Math.round((totalCaja * choferPorcentaje) / 100), [totalCaja]);
  const agenciaGanancia = useMemo(() => Math.round((totalCaja * agenciaPorcentaje) / 100) - totalFiado, [totalCaja, totalFiado]);
  const movilGanancia = useMemo(() => Math.round(totalCaja * movilPorcentaje) / 100 - totalGastos, [totalCaja, totalGastos]);
  const totalAPagar = useMemo(() => (isOwner ? agenciaGanancia : agenciaGanancia + movilGanancia), [isOwner, agenciaGanancia, movilGanancia]);

  const totalCajaFormat = formatAmount(totalCaja);
  const totalGastosFormat = formatAmount(totalGastos);
  const totalFiadosFormat = formatAmount(totalFiado);

  return (
    <DataContext.Provider
      value={{
        trips: data.trips,
        setTrips,
        gastos: data.gastos,
        setGastos,
        fiados: data.fiados,
        setFiados,
        resetData,
        isOwner,
        setIsOwner,
        numeroViajes,
        totalCaja,
        totalCajaFormat,
        totalGastosFormat,
        totalFiadosFormat,
        choferGanancia,
        agenciaGanancia,
        movilGanancia,
        totalAPagar,
        agenciaPorcentaje,
        movilPorcentaje,
        choferPorcentaje
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
